import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

import { Option, Props as OptionProps } from './Option'

export interface Props {
  className?: string
  disabledFirst?: string
  name: string
  error?: boolean
  isDirty?: boolean
  options: OptionProps[]
  required?: boolean
  defaultValue?: string
  onBlur?: FocusEventHandler<HTMLSelectElement>
  onChange?: ChangeEventHandler<HTMLSelectElement>
  onFocus?: FocusEventHandler<HTMLSelectElement>
  value?: string
}
export const Select = memo(
  forwardRef<HTMLSelectElement, Props>(function Select(
    {
      className,
      disabledFirst,
      name,
      error = false,
      isDirty = false,
      options,
      required,
      defaultValue,
      onBlur,
      onChange,
      onFocus,
      value,
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        error={error}
        className={className}
        isDirty={isDirty}
        name={name}
        required={required}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      >
        {disabledFirst ? <Option value={disabledFirst} disabled /> : null}
        {options.map((item, index) => (
          <>
            <Option key={index} {...item} />
          </>
        ))}
      </Container>
    )
  }),
)

Select.displayName = 'Select'

const Container = styled.select<ContainerProps>`
  ${({ theme, error }) => {
    return css`
      width: 100%;
      height: 2.875rem;
      background: url('/arrow-down.svg') no-repeat right center;
      border-bottom: 0.0625rem solid
        ${error
          ? theme.colors.variants.danger
          : rgba(theme.colors.variants.neutralDark2, 0.1)};
      color: ${theme.colors.variants.neutralDark3};
      font-family: ${theme.fontFamily.paragraph};
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
    `
  }}
`

interface ContainerProps {
  error: boolean
  isDirty: boolean
}
