import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  name: string
  placeholder?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, Props>(function Textarea(
    { name, placeholder, required, value, onBlur, onChange, onFocus },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    )
  }),
)
Textarea.displayName = 'Textarea'

const Container = styled.textarea`
  ${({ theme }) => {
    return css`
      width: 100%;
      height: 8.75rem;
      background: transparent;
      border: 0.0625rem solid ${rgba(theme.colors.variants.neutralDark2, 0.2)};
      color: ${theme.colors.variants.neutralDark3};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1rem;
      line-height: 1.75rem;
      margin-top: 1.875rem;
      padding: 1.25rem 1.375rem;
      resize: none;
      text-align: left;

      &::-webkit-input-placeholder {
        color: ${theme.colors.variants.neutralDark3};
        opacity: 0.3;
      }
      &::-moz-placeholder {
        color: ${theme.colors.variants.neutralDark3};
        opacity: 0.3;
      }
      &::-ms-input-placeholder {
        color: ${theme.colors.variants.neutralDark3};
        opacity: 0.3;
      }
      &::placeholder {
        color: ${theme.colors.variants.neutralDark3};
        opacity: 0.3;
      }
    `
  }}
`
