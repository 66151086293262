import styled from '@emotion/styled'
import React, { memo } from 'react'

export const Icon = memo(function Icon() {
  return (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      width="1360.502"
      height="500.501"
      viewBox="0 0 1360.502 500.501"
    >
      <path
        d="M9500.749,253.5h-.711c-27.887-.016-55.694-.929-82.649-2.713-24.527-1.623-48.922-4-72.5-7.07a976.052,976.052,0,0,1-113.5-21.4c-13.988-3.55-27.6-7.391-40.453-11.417-10.267-3.216-20.1-6.566-29.229-9.955-14.766-5.487-22.82-9.289-23.689-9.7l-.017-.008V-247h1360.5V223.764c-4.183-1.038-8.489-2.075-12.8-3.082a1098.093,1098.093,0,0,0-112.693-19.993c-23.023-2.888-46.656-5.146-70.243-6.712-25.908-1.721-52.37-2.644-78.65-2.743-1.435-.005-2.886-.008-4.312-.008-25.984,0-53.186.852-83.157,2.6-26.74,1.565-55.073,3.817-86.619,6.883-58.678,5.7-120.236,13.473-179.769,20.989l-.071.009-5.168.652-.026,0-.1.013h-.006c-57.194,7.219-122.021,15.4-184.755,21.391-32.887,3.14-62.611,5.447-90.872,7.055C9557.56,252.625,9528.608,253.5,9500.749,253.5Z"
        transform="translate(-9137.999 247.001)"
      />
    </Container>
  )
})

const Container = styled.svg`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;

  path {
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
  }
`
