import styled from '@emotion/styled'
import { At, Phone, Pin } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import { Icon } from './Icon'

export interface Props {
  address?: string
  email?: string
  label?: string
  phone?: string
  siteName?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  email,
  label,
  phone,
  siteName,
}: Props) {
  return (
    <Container>
      <Wrapper row space="between" stretch tag="section" wrap>
        <LeftSide>
          {label ? <Label>{label}</Label> : null}

          {siteName ? <SiteName>{siteName}</SiteName> : null}
        </LeftSide>

        <RightSide dial={4}>
          {address ? (
            <Item>
              <Circle dial={5}>
                <Pin />
              </Circle>
              <Address dangerouslySetInnerHTML={{ __html: address }} />
            </Item>
          ) : null}

          {phone ? (
            <Item>
              <Circle dial={5}>
                <Phone />
              </Circle>
              <Anchor
                href={`tel:${phone}`}
                onClick={() => {
                  if (isMobile) {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Phone Number',
                      })
                  }
                }}
              >
                {phone}
              </Anchor>
            </Item>
          ) : null}

          {email ? (
            <Item>
              <Circle dial={5}>
                <At />
              </Circle>
              <Anchor
                href={`mailto:${email}`}
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {email}
              </Anchor>
            </Item>
          ) : null}
        </RightSide>
      </Wrapper>

      <Icon />
    </Container>
  )
})

const Container = styled.section`
  padding-bottom: 6.25rem;
  position: relative;
`

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 10.5rem 6.111vw 4.375rem;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 9.375rem 1.5rem 0;
  }
`

const LeftSide = styled.div`
  width: calc(50% - 7.917vw);
  background: url('/pattern.webp') no-repeat center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding: 5rem 5.069vw;

  @media (max-width: 1199px) {
    width: 100%;
    background: none;
    padding: 0;
  }
`

const Label = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.25rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const SiteName = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 2.5rem;
  }
`

const Item = styled.div`
  min-height: 3.125rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 1.25rem;
  padding-left: 4.125rem;
  position: relative;
  &:first-of-type {
    margin-top: 0;
  }
`

const Circle = styled(FlexBox)`
  width: 3.125rem;
  height: 3.125rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  border-radius: 50%;
  position: absolute;
  top: -0.5625rem;
  left: 0;

  svg {
    width: auto;
    height: 1.25rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight5};
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
`

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  &:hover {
    text-decoration: underline;
  }
`
