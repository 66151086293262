import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  checked?: boolean
  error?: boolean
  name: string
  label?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, Props>(function Checkbox(
    {
      checked,
      error = false,
      name,
      label,
      required,
      value,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    const id = `#form-${name}`

    return (
      <Container dial={5} inline row>
        <InputField
          ref={ref}
          checked={checked}
          id={id}
          name={name}
          type="checkbox"
          required={required}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
        {label ? (
          <Label
            error={error}
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}
      </Container>
    )
  }),
)
Checkbox.displayName = 'Checkbox'

const Container = styled(FlexBox)`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-top: 2.5rem;
  position: relative;
  text-align: left;
`

const InputField = styled.input`
  ${({ theme }) => {
    return css`
      opacity: 0;
      position: absolute;
      visibility: hidden;
      z-index: -1;

      &:checked ~ label {
        &:before {
          border-color: ${theme.colors.variants.neutralLight1};
        }
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    `
  }}
`

const Label = styled.label<ContainerProps>`
  ${({ theme, error }) => {
    return css`
      color: ${theme.colors.variants.neutralDark3};
      padding-left: 1.5rem;
      &:before,
      &:after {
        content: '';
        position: absolute;
        transition: 0.3s ease-in-out;
      }
      &:before {
        width: 0.75rem;
        height: 0.75rem;
        border: 0.125rem solid
          ${error
            ? theme.colors.variants.danger
            : theme.colors.variants.primaryLight};
        border-radius: 50%;
        top: 0.1875rem;
        left: 0;
      }
      &:after {
        width: 0.375rem;
        height: 0.375rem;
        background: ${theme.colors.variants.neutralDark2};
        border-radius: 50%;
        opacity: 0;
        top: 0.375rem;
        left: 0.1875rem;
      }

      a {
        font-weight: 600;
        text-decoration: underline;

        @media (max-width: 767px) {
          pointer-events: none;
        }
      }
    `
  }}
`

interface ContainerProps {
  error: boolean
}
